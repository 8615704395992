.container {
  display: flex;
}

.content {
  min-width: 0;
}

.sliderContainer {
  position: relative;
}

.slider {
  overflow-x: auto;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 50%;

  &::-webkit-scrollbar {
    display: none;
  }

  &FixedItem {
    position: sticky;
    left: 0;
    top: 0;
  }

  &NavigationButton {
    height: unset !important;
    align-self: stretch;
  }
}
