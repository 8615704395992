@use 'styles/base/mixins';
@use 'styles/base/variables';

.addToBasketWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.875rem;

  @include mixins.screen-md {
    flex-direction: row;
  }

  button {
    flex-grow: 1;
    white-space: unset;
  }
}

.articleCounter {
  max-width: 7.5rem;
  margin-right: 1.875rem;
  margin-bottom: 1.875rem;

  button {
    :global(.icon::before) {
      color: var(--gb-brand-primary);
    }
  }

  @include mixins.screen-md {
    margin-bottom: 0;
  }
}

.addToBasketButton {
  :global(.icon::before) {
    font-size: 1.125rem;
  }
}

.addingToBasketButton {
  border-color: variables.$gb_positive_green !important;
  background: variables.$gb_positive_green !important;
}
