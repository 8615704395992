@use 'styles/base/variables';

.technicalDrawings {
  margin-bottom: 1.875rem;

  &Headline {
    font-size: variables.$font-size-body-1 !important;
    line-height: variables.$line-height-body-1 !important;
  }

  &Imgs {
    border: variables.$border-grey;
    margin-bottom: 1.875rem;
  }
}
