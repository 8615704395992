.rangeIndicatorContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  z-index: 10;

  input[type='range'] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;

    width: 10rem;

    z-index: 10;
  }

  /***** Track Styles *****/
  /***** Chrome, Safari, Opera, and Edge Chromium *****/
  input[type='range']::-webkit-slider-runnable-track {
    background: rgba(0, 0, 0, 0.12);
    height: 0.5rem;
    border-radius: 0.5rem;
  }

  /******** Firefox ********/
  input[type='range']::-moz-range-track {
    background: rgba(0, 0, 0, 0.12);
    height: 0.5rem;
    border-radius: 0.5rem;
  }

  /***** Thumb Styles *****/
  /***** Chrome, Safari, Opera, and Edge Chromium *****/
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    background-color: rgb(16, 16, 16);
    width: 2rem;
    height: 0.5rem;
    border-radius: 0.5rem;
  }

  /***** Thumb Styles *****/
  /***** Firefox *****/
  input[type='range']::-moz-range-thumb {
    appearance: none;
    background-color: rgb(16, 16, 16);
    width: 2rem;
    height: 0.5rem;
    border: none; /*Removes extra border that FF applies*/
    border-radius: 0.5rem;
  }
}
