@use 'styles/base/mixins';
@use 'styles/base/variables';

.softContentItem {
  margin-bottom: 2rem;
  text-decoration: none;
  width: 100%;

  &::before {
    display: none;
  }

  &:hover {
    text-decoration: none;
  }

  > h4 {
    margin-bottom: 0.25rem;
    color: var(--gb-brand-primary-light);

    &:hover {
      text-decoration: underline;
    }
  }

  > span {
    color: var(--gb-brand-primary-dark);
    margin-bottom: 0.25rem;
  }

  em {
    font-weight: 600;
  }

  p {
    font-size: 1rem;
    color: variables.$gb_black;
  }
}
