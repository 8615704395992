@use 'styles/base/mixins';
@use 'styles/base/variables';

.wrapper {
  margin-top: 2rem;
  margin-bottom: 4rem;

  @include mixins.screen-sm {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
}

.noResultSuggest {
  margin-top: 1rem;
}

.searchTabs {
  li > div > div {
    padding: 0px;
  }
}
