@use 'styles/base/variables';

.social_share {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;

  &.use_in_content_page {
    margin-bottom: 3.75rem;
  }

  &__name {
    display: block;
    margin-bottom: 0.9375rem;
    font-weight: 700;
    width: 7.5rem;
    white-space: nowrap;
  }

  &__icons {
    display: flex;

    a {
      --border-width: 1px;
      --padding: 0.625rem;

      padding: calc(var(--padding) - var(--border-width));
      transform: translateX(calc(-1 * var(--padding)));
      border: var(--border-width) solid transparent;
      background-color: transparent;

      @media (min-width: 48rem) {
        --padding: 0.5rem;
      }

      &:before {
        content: none;
        display: none;
      }

      &:hover {
        background-color: variables.$gb_black_08;
      }

      &:active {
        background-color: variables.$gb_gdds_cl15;
        border-color: variables.$gb_gdds_cl15;

        svg,
        path {
          fill: variables.$gb-white;
        }
      }

      &:focus-visible {
        border-color: var(--gb-brand-primary);
        outline: var(--border-width) solid var(--gb-brand-primary);
        outline-offset: 0px;
      }
    }
  }
}
