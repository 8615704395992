@use 'styles/base/mixins';

.accordionHeadline {
  margin: 2rem 0;
}

.productDetailGddsAccordion {
  h3, h4 {
    text-transform: none;
  }

  ul {
    font-size: inherit;
    line-height: inherit;
  }

  :global(.c-product-spare-parts__list) {
    @include mixins.typography-body-1();
  }

  :global(.pd-table),
  :global(.c-product-downloads .row),
  :global(.c-product-downloads .row a),
  :global(.table-container--technical-data) table {
    @include mixins.typography-body-2();
  }
}
