@use 'styles/base/mixins';
@use 'styles/base/variables';

.counter {
  @include mixins.screen-md {
    display: flex;
    justify-content: center;
    position: relative;
  }

  button {
    background-color: variables.$gb_grey_050;
    border: variables.$border-solid-background;
    padding: 5px 0;
    width: 33%;

    &.disabled {
      :global(.icon::before) {
        color: variables.$gb_grey_200;
      }
    }

    &.buttonDecrease {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &.buttonIncrease {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  input[type='text'] {
    appearance: none;

    border-top: variables.$border-solid-background;
    border-right: 0;
    border-left: 0;
    border-bottom: variables.$border-solid-background;
    padding: 5px;
    text-align: center;
    width: 33%;

    @include mixins.screen-md {
      width: 35%;
    }
  }
}
