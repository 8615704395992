@use 'styles/base/mixins';
@use 'styles/base/variables';

.filterLabel {
  display: block;
  margin-bottom: 2rem;
  margin-top: -1rem;

  @include mixins.screen-sm {
    margin-bottom: 0.75rem;
    margin-top: 0;
  }

  + div label p {
    font-size: 0.875rem;
  }
}
